import React, { useState } from "react"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const TravelFilter = props => {
    const [currentValue, setCurrentValue] = useState()
    const handleChange = (event) => {
        setCurrentValue(event.target.value)
        props.handleSelect(event.target.value)
    }
    return (
        <div className="travelFilterBar">
            <div className="travelFilter">
                <span>Filter :</span>
                <Box 
                    sx={{ width: 130 }} 
                    m={1}                
                >                
                    <FormControl fullWidth>                
                        <InputLabel id="simple-select-label" >Select</InputLabel>
                        <Select
                            labelId="simple-select-label"
                            id="simple-select"
                            value={currentValue}
                            label="Select"
                            onChange={handleChange}
                        >
                            <MenuItem value={1} name='All'>All</MenuItem>
                            <MenuItem value={2} name='Approve'>Approved</MenuItem>
                            <MenuItem value={3} name='Decline'>Declined</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
            <div className="travelCount">
                Count : {props.listCount}
            </div>
        </div>
    )
}

export default TravelFilter
import React, { useState } from 'react'

const TravelData = props => {
    const traveledUser = props.traveledUser
    const [showDetails, setShowDetails] = useState(false)
    const viewDetails = () => {
        console.log('r u der')
        setShowDetails(prevState => !prevState)
    }
    return (
        <>
            <tr>
                <td>{traveledUser.Dealership}</td>
                <td>{traveledUser.TAF_USERNAME}</td>
                <td>{traveledUser.TAF_USEREMAIL}</td>
                <td>{traveledUser.TAF_FROMDATE}</td>
                <td>{traveledUser.TAF_TODATE}</td>
                <td><button onClick={viewDetails}>
                        {showDetails ? <span>-</span> : <span>+</span>}
                    </button>
                </td>
            </tr>
            {showDetails && <tr>
                <td colSpan="6">
                    <div className='innerWrapper'>
                        <table>
                            <tr>
                                <td style={{'width':'15%' ,'textAlign':'right'}}><strong>Trip Name :</strong></td>
                                <td style={{'width':'100%', 'textAlign':'left'}}>{traveledUser.TAF_TRIPNAME}</td>
                            </tr>
                            <tr>
                                <td style={{'width':'15%' ,'textAlign':'right'}}><strong>Budget :</strong></td>
                                <td style={{'padding': 0}}>
                                    <table style={{'width':'50%'}}>
                                        <tr>
                                            <td style={{ 'textAlign':'right'}}>AirFare:</td>
                                            <td style={{ 'textAlign':'left','width':'10%'}}>${traveledUser.TAF_BUDGET_AIR}</td>
                                            <td style={{ 'textAlign':'right'}}>Hotel:</td>
                                            <td style={{ 'textAlign':'left','width':'10%'}}>${traveledUser.TAF_BUDGET_HOTEL}</td>
                                            <td style={{ 'textAlign':'right'}}>Car:</td>
                                            <td style={{ 'textAlign':'left','width':'10%'}}>${traveledUser.TAF_BUDGET_CAR}</td>
                                            <td style={{ 'textAlign':'right'}}>Others:</td>
                                            <td style={{ 'textAlign':'left','width':'10%'}}>${traveledUser.TAF_BUDGET_OTHERS}</td>
                                            <td style={{ 'textAlign':'right'}}>Total:</td>
                                            <td style={{ 'textAlign':'left','width':'10%'}}>${traveledUser.TAF_BUDGET_TOTAL}</td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>

                            <tr>
                                <td style={{'width':'15%' ,'textAlign':'right'}}><strong>Reason for travel :</strong></td>
                                <td style={{'width':'100%' ,'textAlign':'left'}}>{traveledUser.TAF_REASON}</td>
                            </tr>
                            <tr>
                                <td style={{'width':'15%' ,'textAlign':'right'}}><strong>Persons meeting with :</strong></td>
                                <td style={{'width':'100%' ,'textAlign':'left'}}>{traveledUser.TAF_PEOPLEMETTING}</td>
                            </tr>
                            <tr>
                                <td style={{'width':'15%' ,'textAlign':'right'}}><strong>Notes :</strong></td>
                                <td style={{'width':'100%' ,'textAlign':'left'}}>{traveledUser.TAF_NOTES}</td>
                            </tr>

                        </table>
                        {/* <div><strong>Trip Name :</strong> {traveledUser.TAF_TRIPNAME}</div>
                        <div><strong>Reason for travel :</strong> {traveledUser.TAF_REASON}</div>
                        <div><strong>Persons meeting with :</strong> {traveledUser.TAF_PEOPLEMETTING}</div>
                        <div><strong>Notes :</strong> {traveledUser.TAF_NOTES}</div> */}
                    </div>
                </td>    
            </tr>}
        </>
    )
}

export default TravelData
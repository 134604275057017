import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputLabel from '@mui/material/InputLabel'
import "./TravelForm.css"
import addDays from 'date-fns/addDays'
import axios from 'axios'

const TravelForm = () => {
    const [dealersOptions, setDealersOptions] = useState([])
    const [dealership, setDealership] = useState('')
    const [tripName, setTripName] = useState()
    const [userName,setUserName]=useState()
    const [userEmail,setUserEmail]=useState()
    const [reasonForTravel, setReasonForTravel] = useState()
    const [personMeeting, setPersonMeeting] = useState()
    const [notesText, setNotesText] = useState()
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [total, setTotal] = useState()
    const [airfareVal, setAirfareVal] = useState()
    const [hotelVal, setHotelVal] = useState()
    const [carVal, setCarVal] = useState()
    const [othersVal, setOthersVal] = useState()
    const[formErrors,setFormError]=useState({})

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        if(dealersOptions.length === 0) {
            fetch('https://travelformapi.axelautomotive.com/api/TravelForm/GetDealerShips',
            { 
                method: "GET", 
                mode: 'cors', 
                headers: { 'Content-Type': 'application/json',},
                signal: signal,
            })
            .then(response => response.json())
            .then(json => {
                setDealersOptions(json.response.recordset)
            })
        }
        return () => {
            controller.abort()
        }        
    }, [dealersOptions])    

    const handleFromDate = (newValue) => {
        setFromDate(newValue)
        setToDate(null)
    };

    const handleToDate = (newValue) => {
        setToDate(newValue);
    };

    const handleDealership = e => {
        const selected = e.target.textContent
        const selectedObj = Object.keys(dealersOptions).find(key => 
            dealersOptions[key].label === selected)
        setDealership(dealersOptions[selectedObj]);
        setFormError({ ...formErrors, dealership: ''})
    }
    
    const handleTripName = (e) => {
        setTripName(e.target.value)
        if(e.target.value.length > 0) {
            setFormError({ ...formErrors, tripName: ''})
        }
    }

    const handleBudget = (event) => {
        const { name, value } = event.target
        const aVal = isNaN(airfareVal) ? 0 : airfareVal;
        const cVal = isNaN(carVal) ? 0 : carVal;
        const hVal = isNaN(hotelVal) ? 0 : hotelVal;
        const oVal = isNaN(othersVal) ? 0 : othersVal;
        let total = 0
        if (name === "airfare") {
            total = cVal + hVal + oVal + Number(value)
            setAirfareVal(Number(value))
        }
        if (name === "car") {
            total = aVal + hVal + oVal + Number(value)

            setCarVal(Number(value))
        }
        if (name === "hotel") {
            total = aVal + cVal + oVal + Number(value)
            setHotelVal(Number(value))
        }
        if (name === "others") {
            total = aVal + cVal + hVal + Number(value)
            setOthersVal(Number(value))
        }
        setTotal(total)
    }

    const handleUserName = (event) => {
        setUserName(event.target.value)
        if(event.target.value.length > 0) {
            setFormError({ ...formErrors, userName: ''})
        }        
    }  
    const handleUserEmail = (event) => {
        const keyVal = event.target.value
        setUserEmail(keyVal)    
        if(event.target.value.length > 0) {
            setFormError({ ...formErrors, userEmail: ''})
        }
    }  
    const handlerEmailBlur = event => {
        const keyVal = event.target.value
        setUserEmail(keyVal)
        const emailValidate = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = emailValidate.test(String(keyVal).toLowerCase())
        if (!result) {
            setFormError({ ...formErrors, userEmail: 'Invalid Email address'})
        }
    }
    const handleReasonForTravel = (event) => {
        setReasonForTravel(event.target.value)
        if(event.target.value.length > 0) {
            setFormError({ ...formErrors, reasonForTravel: ''})
        }
    }

    const handlePersonMeeting = (event) => {
        setPersonMeeting(event.target.value)
    }
    const handleNotesText = (event) => {
        setNotesText(event.target.value)
    }
    const handleReset = () => {
        setDealership('')
        setTripName('')
        setUserName('')
        setUserEmail('')
        setReasonForTravel('')
        setPersonMeeting('')
        setNotesText('')
        setFromDate(null)
        setToDate(null)
        setTotal(0)
        setAirfareVal('')
        setCarVal('')
        setHotelVal('')
        setOthersVal('')
        setFormError({})
    }

    const validate = () => {
        const errors={}
        if(!reasonForTravel){
            errors.reasonForTravel="Please enter valid reason for travel."
        }
        else{
            errors.reasonForTravel=""
        }
        if(!tripName){
            errors.tripName="Please enter trip name."
        }
        else{
            errors.tripName=""
        }
        if(!userName){
            errors.userName="Please enter user name."
        }
        else{
            errors.userName=""
        }
        if(!userEmail){
            errors.userEmail="Please enter user email."
        }
        else{
            errors.userEmail=""
        }
        if(!dealership){
            errors.dealership="Please select dealership."
        } 
        else{
            errors.dealership=""
        }
        return errors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if(reasonForTravel && tripName && userName && dealership && userEmail)  {
        
        const formObj = {
            ACTION: 'A',
            TAF_ID: 0,
            TAF_DEALERSHIP_ID: dealership.id,
            TAF_TRIPNAME: tripName,
            TAF_FROMDATE: fromDate,
            TAF_TODATE: toDate,
            TAF_REASON: reasonForTravel,
            TAF_PEOPLEMETTING: personMeeting,
            TAF_NOTES: notesText,
            TAF_BUDGET_AIR: airfareVal,
            TAF_BUDGET_CAR: carVal,
            TAF_BUDGET_HOTEL: hotelVal,
            TAF_BUDGET_OTHERS: othersVal,
            TAF_BUDGET_TOTAL: total,
            TAF_USERNAME: userName,
            TAF_USEREMAIL:userEmail,
            TAF_DEALERSHIP_NAME: dealership.label,
        }

        const postApproval = {
            method: 'post',
            url: 'https://travelformapi.axelautomotive.com/api/TravelForm/TravelFormAction',
            data: JSON.stringify(formObj),
            headers: {
              'Content-Type': 'application/json'
             },
            json: true,
            e: e,
        }

        axios(postApproval)
            .then(response =>  response.data)
            .then(data => {
              const  rid = data.response
                // setRefId(data)
                // console.log(RefId);

                const mailObj = {
                    ACTION: 'A',
                    TAF_ID: 0,
                    TAF_DEALERSHIP_ID: dealership.id,
                    TAF_TRIPNAME: tripName,
                    TAF_FROMDATE: fromDate,
                    TAF_TODATE: toDate,
                    TAF_REASON: reasonForTravel,
                    TAF_PEOPLEMETTING: personMeeting,
                    TAF_NOTES: notesText,
                    TAF_BUDGET_AIR: airfareVal,
                    TAF_BUDGET_CAR: carVal,
                    TAF_BUDGET_HOTEL: hotelVal,
                    TAF_BUDGET_OTHERS: othersVal,
                    TAF_BUDGET_TOTAL: total,
                    TAF_USERNAME: userName,
                    TAF_USEREMAIL:userEmail,
                    TAF_DEALERSHIP_NAME: dealership.label,
                    REFID:rid
                }

                if (data.status >= 200) {
                    const postSubMail = {
                        method: 'post',
                        url: 'https://travelformapi.axelautomotive.com/api/TravelForm/TFSubmissionMail',
                        data: JSON.stringify(mailObj),
                        headers: {
                          'Content-Type': 'application/json'
                         },
                        json: true,
                        e: e,
                    }
            axios(postSubMail)
            .then(response => response.data)
            .then(data => {
                if (data.status >= 200) {
                    handleReset()
                    setFormError('');
                    alert('Travel form is submitted successfully and Mail Sent');
                 }
            })
            .catch(error => { console.log('Error: ', error )})
                }

            })
            .catch(error => { console.log('Error: ', error )})
        
        // return fetch("https://travelformapi.axelautomotive.com/api/TravelForm/TravelFormAction", {
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     method: 'POST',
        //     body: JSON.stringify(formObj)
        // }).then(response => {
        //     if (response.status >= 200 && response.status < 300) {
        //         handleReset()
        //         setFormError('');
        //         return fetch("https://travelformapi.axelautomotive.com/api/TravelForm/TFSubmissionMail", {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json'
        //         },
        //         method: 'POST',
        //         body: JSON.stringify(formObj)
        //         }).then(response => {
        //             if (response.status >= 200 && response.status < 300) {
        //                 alert('Travel form is submitted successfully and Mail Sent');
        //             }
        //         }).catch(err => {
        //             handleReset()
        //         }); 
        //     }
        // }).catch(err => {
        //     handleReset()
        // });
      }
      else{
        setFormError(validate())
      }
      
    }
    const minDate = new Date(Date.now());
    
    return (
        <>
            <div className='form-body'>
                <Grid container spacing={2}>
                    <Grid md={2} xs={12}>
                        <InputLabel className='dealership-label form-label' required>Dealership</InputLabel>
                    </Grid>
                    <Grid md={4} xs={12} className="dealer-part">
                        <Autocomplete
                            size={"small"}
                            id="combo-box-dealership"
                            options={dealersOptions}
                            sx={{ width: 300 }}
                            value={dealership}
                            onChange={handleDealership}
                            renderInput={(params) => <TextField {...params} label="Select Dealership" />}
                        />
                        <p className='errorWrapper'>{formErrors.dealership}</p>
                    </Grid>                    
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={2}>
                        <InputLabel className='trip-name-label form-label' required>Trip Name</InputLabel>
                    </Grid>
                    <Grid md={4} xs={12} className="dealer-part">
                        <TextField
                            id="outlined-basic"
                            label="Trip Name"
                            variant="outlined"
                            size={"small"}
                            value={tripName}
                            onChange={handleTripName}
                        />
                        <p className='errorWrapper'>{formErrors.tripName}</p>
                    </Grid>                    
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={2}>
                        <InputLabel className='user-name-label form-label' required>User Name</InputLabel>
                    </Grid>
                    <Grid md={4} xs={12} className="dealer-part">
                        <TextField
                            id="outlined-basic"
                            label="User Name"
                            variant="outlined"
                            size={"small"}
                            value={userName}
                            onChange={handleUserName}
                            name="user_name"
                        />
                        <p className='errorWrapper'>{formErrors.userName}</p>
                    </Grid>                    
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={2}>
                        <InputLabel className='user-name-label form-label' required>User Email</InputLabel>
                    </Grid>
                    <Grid md={4} xs={12} className="dealer-part">
                        <TextField
                            id="outlined-basic"
                            label="User Email"
                            variant="outlined"
                            size={"small"}
                            value={userEmail}
                            onChange={handleUserEmail}
                            onBlur={handlerEmailBlur}
                        />
                        <p className='errorWrapper'>{formErrors.userEmail}</p>
                    </Grid>                    
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={2}>
                        <InputLabel className='dealership-label form-label'>Dates</InputLabel>
                    </Grid>
                    <Grid md={10} >
                        <Grid container>
                            <Grid md={4} xs={7} className="date-part from-date">
                                <InputLabel className='date-from-label'> From </InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Select From Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={fromDate}
                                        onChange={handleFromDate}
                                        size={"small"}
                                        className={"date-cls"}
                                        minDate={minDate}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid md={4} xs={7} className="date-part to-date  ">
                                <InputLabel className='date-to-label'> To </InputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Select To Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={toDate}
                                        onChange={handleToDate}
                                        size={"small"}
                                        className={"date-cls"}
                                        minDate={addDays(new Date(), 1)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={2}>
                        <InputLabel className='dealership-label form-label' required>Reason for travel</InputLabel>
                    </Grid>
                    <Grid md={6} xs={12} className="text-area-part">
                        <TextField
                            id="standard-textarea"
                            label="Please enter valid reason"
                            placeholder="Placeholder"
                            variant="outlined"
                            size={"large"}
                            multiline
                            rows={3}
                            onChange={handleReasonForTravel}
                            value={reasonForTravel}
                        />
                        <p className='errorWrapper'>{formErrors.reasonForTravel}</p>
                    </Grid>                    
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={2}>
                        <InputLabel className='dealership-label form-label'>Persons meeting with</InputLabel>
                    </Grid>
                    <Grid md={6} xs={12} className="text-area-part">
                        <TextField
                            id="standard-textarea"
                            label="Please enter valid information"
                            placeholder="Placeholder"
                            variant="outlined"
                            size={"large"}
                            multiline
                            rows={3}
                            onChange={handlePersonMeeting}
                            value={personMeeting}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={2}>
                        <InputLabel className='dealership-label form-label'>Budget</InputLabel>
                    </Grid>
                    <Grid md={8}>
                        <Grid container spacing={0} className="budget-first-div">
                            <Grid md={2} xs={4} className="budget-div">
                                <InputLabel className='dealership-label budget-label'>Airfare </InputLabel>
                                <TextField
                                    id="standard-textarea"
                                    label=""
                                    placeholder="0"
                                    size={"small"}
                                    variant="outlined"
                                    type={"number"}
                                    onChange={e => handleBudget(e)}
                                    value={airfareVal}
                                    name="airfare"
                                />
                            </Grid>
                            <Grid md={2} xs={4} className="budget-div">
                                <InputLabel className='dealership-label budget-label'>Hotel </InputLabel>
                                <TextField
                                    id="standard-textarea"
                                    label=""
                                    placeholder="0"
                                    size={"small"}
                                    variant="outlined"
                                    type={"number"}
                                    value={hotelVal}
                                    name="hotel"
                                    onChange={e => handleBudget(e)}
                                />
                            </Grid>
                            <Grid md={2} xs={4} className="budget-div">
                                <InputLabel className='dealership-label budget-label'>Car </InputLabel>
                                <TextField
                                    id="standard-textarea"
                                    label=""
                                    placeholder="0"
                                    size={"small"}
                                    variant="outlined"
                                    type={"number"}
                                    onChange={e => handleBudget(e)}
                                    value={carVal}
                                    name="car"
                                />
                            </Grid>
                            <Grid md={2} xs={4} className="budget-div">
                                <InputLabel className='dealership-label budget-label'>Others </InputLabel>
                                <TextField
                                    id="standard-textarea"
                                    label=""
                                    placeholder="0"
                                    size={"small"}
                                    variant="outlined"
                                    type={"number"}
                                    onChange={e => handleBudget(e)}
                                    value={othersVal}
                                    name="others"
                                />
                            </Grid>
                            <Grid md={2} xs={4} className="budget-div">
                                <InputLabel className='dealership-label budget-label'>Total </InputLabel>
                                <TextField
                                    id="standard-textarea"
                                    label=""
                                    placeholder=""
                                    size={"small"}
                                    variant="outlined"
                                    type={"number"}
                                    disabled={true}
                                    value={total}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid md={2}>
                        <InputLabel className='dealership-label form-label'>Notes</InputLabel>
                    </Grid>
                    <Grid md={6} xs={12} className="text-area-part">
                        <TextField
                            id="standard-textarea"
                            label="Please enter valid notes"
                            placeholder="Placeholder"
                            size={"large"}
                            variant="outlined"
                            multiline
                            rows={3}
                            onChange={handleNotesText}
                            value={notesText}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className='footer'>
                <Button variant="secondary" onClick={handleReset}>Reset</Button>
                <Button variant="contained" onClick={handleSubmit}>Submit</Button>
            </div>
        </>
    )
}

export default TravelForm;
import React from 'react';
// import './App.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

function travelgrid() {
  const columnDefs= [
    { headerName: "Dealership", field: "Dealership" },    
    { headerName: "TripName", field: "TAF_TRIPNAME",},
    { headerName: "UserName", field: "TAF_USERNAME",},
    { headerName: "UserEmail", field: "TAF_USEREMAIL",},
    { headerName: "FromDate",field: "TAF_FROMDATE",},
    { headerName: "ToDate", field: "TAF_TODATE" },
    { headerName: "Air",field: "TAF_BUDGET_AIR",},
    { headerName: "Hotel", field: "TAF_BUDGET_HOTEL" },
    { headerName: "Car", field: "TAF_BUDGET_CAR" },
    { headerName: "Others", field: "TAF_BUDGET_OTHERS",}, 
    { headerName: "Total",field: "TAF_BUDGET_TOTAL",},
    { headerName: "Reason", field: "TAF_REASON" },
    { headerName: "Metting", field: "TAF_PEOPLEMETTING",}, 
    { headerName: "Notes", field: "TAF_NOTES" },
    // { headerName: "ID", field: "id" },
    // { headerName: "Name", field: "name",}, 
    // {headerName: "Email",field: "email",},
    // { headerName: "Body", field: "body" },    
    ]


const defaultColDef={
  sortable:true,
  editable:true,
  flex:1,filter:false,
  floatingFilter:false
}

const onGridReady=(params)=>{
  console.log("grid is ready")
//   fetch("https://jsonplaceholder.typicode.com/comments").then(resp=>resp.json())
  fetch("http://travelformapi.axelautomotive.com/api/TravelForm/GetTravelFormData").then(resp=>resp.json())
  .then(resp=>{console.log(resp.response.recordset)
    params.api.applyTransaction({add:resp.response.recordset})})
}
  return (
    <div className="App">
      {/* <h1 align="center">React-App</h1> */}
      {/* <h3>Student Details</h3> */}
      <div className="ag-theme-alpine" style={ {height: '500px',width:'2000px'} }>
        <AgGridReact
            columnDefs={columnDefs}
            // rowData={rowData}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            icons="icons"
    floatingFilter="false" >
        </AgGridReact>
      </div>
    </div>
  );
}

export default travelgrid;
import React, { useEffect, useState } from "react"
import axios from 'axios'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import './DeclineCheckRequest.css'

const DeclineCheckRequest = props => {
    const [comment, setComment] = useState('')
    const [travelResponse, setTravelResponse] = useState()
    const [controlsDisabled, setcontrolsDisabled] = useState(false)
    const recordId = window.location.search.split("?")[1]

    useEffect(() => {
        props.updateTitle('Decline Check Request')
    }, [props])

    const onDecline = () => {
        
        const teavelObj = {
            "TAF_REF_ID": recordId,
            "TAF_EMAIL_STATUS":"Decline",
            "TAF_EMAIL_COMMENT": comment,
        }
                        
        const postDecline = {
            method: 'post',
            url: 'https://travelformapi.axelautomotive.com/api/TravelForm/ApproveDeclineAction',
            data: JSON.stringify(teavelObj),
            headers: {
                'Content-Type': 'application/json'
                },
            json: true,
        }

        axios(postDecline)
            .then(response => response.data)
            .then(data => {
                setTravelResponse(data.response)
                setComment('')
                setcontrolsDisabled(true)
            })
            .catch(error => {
                setTravelResponse('Error : Something went wrong, please try again later.')
                console.log('Error: ', error )
            })        
    }

    const handleOnChange = (e) => {
        setComment(e.target.value)
    }

    return (
        <div className="declineForm">
            <div className="responseMsg">{travelResponse}</div>
            {/* <div>Decline check request form {recordId}, are you sure?</div>
            <textarea 
                value={comment} 
                onChange={handleOnChange} 
                rows="10" 
                placeholder="Provide declined reason(Optional)"
                maxLength='500'
                disabled={controlsDisabled}
            />
            <Box
                component="span"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Button variant="secondary" disabled={controlsDisabled}>Cancel</Button>
                <Button variant="contained" color="error" onClick={onDecline} disabled={controlsDisabled}>Decline</Button>
            </Box>      */}

{!controlsDisabled &&
            <>
                <div>Decline check request form {recordId}, are you sure?</div>
                <textarea 
                    value={comment} 
                    onChange={handleOnChange} 
                    rows="10" 
                    placeholder="Provide declined reason(Optional)"
                    maxLength='500'
                />
                <Box
                    component="span"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button variant="secondary" >Cancel</Button>
                    <Button variant="contained" color="error" onClick={onDecline} >Decline</Button>
                </Box> 
            </>
            }   



        </div>
    )
}

export default DeclineCheckRequest
import React, { useEffect, useState, useCallback } from 'react'
import TravelData from './TravelData'
import axios from 'axios'
import './TravelTable.css'
import TravelFilter from './TravelFilter'

const TravelTable = props =>{
    const [travelList, setTravelList] = useState()
    props.updateTitle('Travel Approval Records')
    
    const callPostMethod = useCallback((signal, status) => {
        
        const teavelObj = {
            'Status': status,
        }
        const postApproval = {
            method: 'post',
            url: 'https://travelformapi.axelautomotive.com/api/TravelForm/GetTravelFormData',
            data: JSON.stringify(teavelObj),
            headers: {
              'Content-Type': 'application/json'
             },
            json: true,
            signal: signal,
        }

        axios(postApproval)
            .then(response => response.data)
            .then(result => {
                const records = result.response.recordset
                setTravelList(records)
            })
            .catch(error => {
                console.log('Error: ', error )
            })
    }, [])
    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        const status = ''
        callPostMethod(signal, status)

        return () => {
            controller.abort()
        }
    }, [callPostMethod])

    const handleSelect = (val) => {
        let status = ''
        if(val === 2) {
            status = 'Approve'
        } else if(val === 3){
            status = 'Decline'
        }
        callPostMethod('', status)
    }

    const listCount = travelList && travelList.length

    return (
        <>
        <TravelFilter handleSelect={handleSelect} listCount={listCount}/>
        <div className='grid-wrapper'>
            <table>
                <thead>
                    <tr>
                        <th>Dealership</th>
                        <th>User Name</th>
                        <th>User Email</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {travelList && travelList.map((traveledUser) => {
                        return (
                            <TravelData traveledUser={traveledUser} key={traveledUser.TAF_ID} />
                        )
                    })}
                </tbody>
            </table>
        </div>
        </>
    )
}

export default TravelTable
import React, { useEffect, useState, useCallback } from "react"
import axios from 'axios'
import './ApproveCheckRequest.css'

const ApproveCheckRequest = props => {

    const [travelResponse, setTravelResponse] = useState('')
    props.updateTitle('Approve Check Request')

    const callPostMethod = useCallback((signal) => {
        const recordId = window.location.search.split("?")[1]
        const teavelObj = {
            "TAF_REF_ID": recordId,
            "TAF_EMAIL_STATUS":"Approve",
            "TAF_EMAIL_COMMENT": "",
        }
        const postApproval = {
            method: 'post',
            url: 'https://travelformapi.axelautomotive.com/api/TravelForm/ApproveDeclineAction',
            data: JSON.stringify(teavelObj),
            headers: {
              'Content-Type': 'application/json'
             },
            json: true,
            signal: signal,
        }

        axios(postApproval)
            .then(response => response.data)
            .then(data => {
                setTravelResponse(data.response)
            })
            .catch(error => {
                setTravelResponse('Error : Something went wrong, please try again later.')
                console.log('Error: ', error )
            })
    }, [])
    

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        callPostMethod(signal)

        return () => {
            controller.abort()
        }
    }, [callPostMethod])
    
    return (
        <div className="approvalForm"> 
            {travelResponse}       
        </div>
    )
}

export default ApproveCheckRequest
import React, { useState } from 'react';
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import headerLogo from './swickard_logo.png'
import './App.css';
import TravelForm from "./pages/travel-form/TravelForm"
import Travelgrid from "./pages/travelgrid/travelgrid"
import TravelTable from './pages/travelgrid/TravelTable'
import ApproveCheckRequest from './pages/approve-check-request/ApproveCheckRequest'
import DeclineCheckRequest from './pages/decline-check-request/DeclineCheckRequest'

function App() {
  const [pagetTitle, setPageTitle] = useState('Travel Approval Form')
  const updateTitle = title => {
    setPageTitle(title)
  }
  return (
    <div className="App">
      <div className="App-header">
        <label className='header-label'>
          <a style={{ 'paddingRight': '4rem' }} href='https://www.swickard.com/'>
            <img src={headerLogo} title="SWICKARD AUTO GROUP" alt="SWICKARD AUTO GROUP" />
          </a>
          <span>{pagetTitle}</span>
        </label>
      </div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={ <TravelForm updateTitle={updateTitle} />} />
          <Route path='/DashboardTravelTable' element={ <Travelgrid />} />
          <Route path='/Dashboard' element={ <TravelTable updateTitle={updateTitle} /> } />
          <Route path='/Approve' element={ <ApproveCheckRequest updateTitle={updateTitle} /> } />
          <Route path='/Decline' element={ <DeclineCheckRequest updateTitle={updateTitle} /> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;